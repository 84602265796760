@import '/assets/styles/function';


#encart {
    .container-encart {
      
        height: pxToVw(687px, 1920px);
        width: 100%;
        background-color: #F5F2F2;
        margin: auto 0;
    //   text-align: center;
        h2 {
           text-transform: uppercase;
           color: #007263;
           font-size: pxToVw(45px, 1920px);
           font-weight: 600;
           margin-left: pxToVw(825px, 1920px);
           padding-top: pxToVw(27px, 1920px);
           margin-bottom: pxToVw(47px, 1920px);
         


        }

        .green-container {
            position: relative;
            margin: auto;
            height: pxToVw(490px, 1920px);
            width: pxToVw(1835px, 1920px);
            background-color: #007263;
            box-shadow: 3px 3px 6px #00000029;
            border-radius: pxToVw(23px, 1920px);

            h4{
                color: #FFFFFF;
                font-size: pxToVw(28px, 1920px);
                font-weight: 600;
                line-height: pxToVw(42px, 1920px);
                padding-top: 2.6041666667vw;
                margin-bottom: pxToVw(31px, 1920px);
                font-weight: 700;
                margin-left: pxToVw(769px, 1920px);
            }

            p{
                color: #FFFFFF;
                font-size: pxToVw(28px, 1920px);
                margin-left: pxToVw(769px, 1920px);
                // padding-top: pxToVw(200px, 1920px);
                // padding-top: pxToVw(50px, 1920px);
                line-height: pxToVw(42px, 1920px);
                margin-bottom: pxToVw(62px, 1920px);
                width: pxtoVw(759px, 1920px);
        

            }

            img {
                position: absolute;
                top: pxToVw(-70px, 1920px);
                left: pxToVw(16px, 1920px);
                height: pxToVw(449px, 1920px);
                width: pxtoVw(689px, 1920px);
            }
        }
    }


    .form {
        //margin-left: pxToVw(450px, 1920px);
        display:flex;
        //text-align: center;
        justify-content: end;
        margin-right: pxToVw(100px, 1920px);
        
        input:first-of-type{
             margin-right: pxToVw(82px, 1920px);
        }
        
        .button {
            width: pxToVw(334px, 1920px);
            max-height: pxToVw(75px, 1920px) !important;
            background-color: white;
            color:#007263;
            border: 4px solid #007263;
            border-radius: pxToVw(44px, 1920px);
            cursor: pointer;
            font-size: pxToVw(22px, 1572px);
            padding-top: pxToVw(18px, 1920px);
            padding-bottom: pxToVw(40px, 1920px);
            margin-bottom: pxToVw(21px, 1920px);
            //margin-left: pxToVw(160px, 1920px);
         

            transition: background-color .3s ease;

            &:hover{
                color: white;
                background-color: rgb(64, 149, 138);
            }

            
        }
      
    }
}